import React from 'react';

import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();


var authEndpoint = 'https://asdc.cc/meeting'
var sdkKey = ''
var meetingNumber = '123456789'
var role = 0
var leaveUrl = 'https://asdc.cc'
var userName = 'React'
var userEmail = ''
var passWord = ''

function App() {

  const url = new URL(window.location.href);
  
  // Get the value of the "name" query parameter
  meetingNumber= url.searchParams.get("meetingNumber");
  
  // Get the value of the "age" query parameter
  passWord= url.searchParams.get("passWord");
  
    // Get the value of the "age" query parameter
  role= parseInt(url.searchParams.get("role"));

  //var authEndpoint = ''
  //var sdkKey = ''
  //var meetingNumber = '123456789'
  //var passWord = ''
  //var role = 0
  var userName = 'React'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  //var leaveUrl = 'http://localhost:3000'

  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature,response.sdkKey)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature,sdkKey) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting SDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;
